import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  const year = (new Date()).getFullYear();
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <img src="logo512.jpg" className="App-logo" alt="Tinyflights" />
        </h1>
        <p>pleasure flights in and around the UK</p>
      </header>

      <footer className="App-footer">
        <p>&copy; TinyFlights {year}.</p>
        <p>TinyFlights Ltd is registered in England under company number 12180191. Our registered office is: 55 Briardene Way, Newcastle upon Tyne, NE27 0XP</p>
      </footer>
    </div>
  );
}

export default App;
